import styled, { ThemedComponentProps } from "styled-components";
import { useTopbar } from "./Topbar.hook";
import { useFirebaseContext } from "../../../modules/common/providers/FirebaseProvider";
import { firebaseSignOut } from "../../firebase";
import BasicButton from "../../../modules/common/components/BasicButton";

function Topbar() {
  const {
    models: { title },
  } = useTopbar();

  const user = useFirebaseContext();

  return (
    <Wrapper>
      <Pathname>{title}</Pathname>
      <AccountWrapper>
        <Account>{user?.email}</Account>
        <BasicButton
          text="Sign Out"
          fontWeight="bold"
          backgroundColor="red"
          hoverBackgroundColor="lightRed"
          onClick={firebaseSignOut}
          width="80px"
        />
      </AccountWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  padding: `${theme.spacing.small}px ${theme.spacing.regular}px`,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  height: theme.topBarHeight,
}));

const Pathname = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  ...theme.font.head2,
  fontWeight: "bold",
}));

const AccountWrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing.small,
}));

const Account = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  ...theme.font.body3,
  fontWeight: "bold",
  fontStyle: "italic",
  color: theme.color.darkGray,
  fontSize: 15,
}));

export default Topbar;
