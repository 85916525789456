import styled, { ThemedComponentProps } from "styled-components";
import { useAuthContainer } from "./AuthContainer.hook";
import Loader from "../../common/components/Loader";

function AuthContainer() {
  const {
    models: { email, password, isLoading },
    operations: { onChange, onSubmit },
  } = useAuthContainer();

  return (
    <Wrapper>
      <Title>Carelogue Admin</Title>
      <Body>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
        <AuthInputWrapper onSubmit={onSubmit}>
          <AuthInput
            placeholder="Email"
            value={email}
            onChange={(event) => onChange(event, "email")}
          />
          <AuthInput
            placeholder="Password"
            type="password"
            value={password}
            onChange={(event) => onChange(event, "password")}
          />
          <Button type="submit" onClick={onSubmit}>
            {isLoading ? <Loader /> : "Sign In"}
          </Button>
        </AuthInputWrapper>
      </Body>
    </Wrapper>
  );
}

const Wrapper = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100vw",
  height: "100vh",
  position: "relative",
}));

const Title = styled.div(({ theme }: ThemedComponentProps) => ({
  ...theme.font.head5,
  color: theme.color.black,
  marginBottom: theme.spacing.middle,
}));

const Body = styled.div(({ theme }: ThemedComponentProps) => ({
  width: "60%",
  border: `1px solid ${theme.color.black7}`,
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  justifyContent: "center",
  padding: `${theme.spacing.regular}px 0px`,
  backgroundColor: "rgba(0,0,0,0.1)",
}));

const LogoWrapper = styled.div(({ theme }: ThemedComponentProps) => ({
  flex: 1,
  padding: `0px ${theme.spacing.regular}px`,

  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
}));

const Logo = styled.img.attrs(() => ({
  src: "/carelogue_logo.png",
}))(() => ({
  width: "90%",
  objectFit: "contain",
}));

const AuthInputWrapper = styled.form(({ theme }: ThemedComponentProps) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  height: "100%",
  alignItems: "center",
  justifyContent: "center",
  padding: `0px ${theme.spacing.regular}px`,
  gap: theme.spacing.regular,
  borderLeft: `1px solid ${theme.color.black7}`,
}));

const AuthInput = styled.input(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  padding: "5px 10px",
  borderRadius: 5,
  borderBottom: `1px solid ${theme.color.black6}`,
}));

const Button = styled.button(({ theme }: ThemedComponentProps) => ({
  padding: "5px 10px",
  height: 40,
  width: "150px",
  fontWeight: "bold",
  marginTop: "auto",
  borderRadius: 5,
  color: theme.color.white,
  backgroundColor: theme.color.black1,
}));

export default AuthContainer;
