import { useParams } from "react-router-dom";
import {
  Measure,
  useGetReceiverNameQuery,
  useGetWeeklyReceiverMeasuresQuery,
} from "../../../lib/apollo/graphql/generated";
import { useState } from "react";

export type CategoryTree = {
  [key: string]: {
    id: string;
    mediums: {
      [key: string]: Array<{
        small: string;
        measure: number;
      }>;
    };
  };
};

const getCategoryTree = (data?: Measure[]) => {
  if (!data) {
    return {};
  }

  const tree: CategoryTree = {};

  for (const measure of data) {
    const large = measure.large_category;

    if (!large) {
      continue;
    }

    if (!tree[large] && measure.category_id) {
      tree[large] = {
        id: measure.category_id,
        mediums: {},
      };
    }

    if (!measure.small_category || !measure.medium_category) {
      continue;
    }

    /**
     * Currently looped medium category
     */
    const medium = measure.medium_category;
    /**
     * Small categories already a part of the tree
     */
    const smalls = tree[large].mediums?.[medium] || [];
    /**
     * Whether currently looped small category is alrady included in the smalls array or not
     */
    const isSmallDuplicated = !!smalls.find(
      ({ small }) => small === measure.small_category
    );

    if (!isSmallDuplicated) {
      smalls.push({
        small: measure.small_category,
        measure: measure.measure || 0,
      });
    }

    const mediums = {
      ...tree[large].mediums,
      [medium]: smalls,
    };

    tree[large] = {
      ...tree[large],
      mediums,
    };
  }

  return tree;
};

export const useWeeklyRecordDetailConatiner = () => {
  const [selectedCategory, setSelectedCategory] = useState<null | string>(null);

  const params = useParams();
  const { receiverId, period } = params;

  if (!receiverId || !period) {
    throw new Error("주간기록 조회에 필요한 데이터가 부족합니다.");
  }

  const [year, month, week] = period.split("-");

  const { data, loading } = useGetWeeklyReceiverMeasuresQuery({
    variables: {
      receiverId: receiverId!,
      year: +year,
      month: +month,
      week: +week,
    },
  });

  const { data: receiverData } = useGetReceiverNameQuery({
    variables: {
      receiverId,
    },
  });

  const tree = getCategoryTree(data?.getWeeklyReceiverMeasures as Measure[]);

  const onLargeCategorySelect = (largeCategory: string | null) => {
    if (largeCategory && !tree[largeCategory]) {
      alert("대분류를 선택할 수 없습니다. 다시 시도해 주세요.");
      return;
    }

    setSelectedCategory(largeCategory);
  };

  return {
    models: {
      loading,
      categoryTree: tree,
      year,
      month,
      week,
      receiver: receiverData?.getReceiver.name,
      receiverId,
      selectedCategory,
    },
    operations: {
      onLargeCategorySelect,
    },
  };
};
