import styled from "styled-components";
import { DailyReportGroupByWeek } from "../../../lib/apollo/graphql/generated";
import { Fragment } from "react";

const WEEKDAYS = ["일", "월", "화", "수", "목", "금", "토"];

interface WeeklyRecordRowProps {
  period: {
    year: number;
    month: number;
  };
  receiverId: string;
  weekIndex: number;
  weeklyReports: DailyReportGroupByWeek;
}

export default function WeeklyRecordsRow({
  weeklyReports,
  receiverId,
  period,
  weekIndex,
}: WeeklyRecordRowProps) {
  const hasDailyReports = !!weeklyReports.dailyReports?.length;
  const { year, month } = period;

  return (
    <Wrapper>
      <WeekLabel>
        {month}월 {weekIndex}주차
        <WeeklyReportButton $hasWeeklyReport={hasDailyReports}>
          {hasDailyReports ? (
            <a
              href={`/detail/weekly/${receiverId}/${year}-${month}-${weekIndex}`}
              target="_blank"
              rel="noreferrer"
            >
              주간 리포트 <i className="bi bi-box-arrow-up-right" />
            </a>
          ) : (
            <>주간 리포트 없음</>
          )}
        </WeeklyReportButton>
      </WeekLabel>
      <TablesRowsWrapper>
        <TableRow $isEmpty={!hasDailyReports}>
          {hasDailyReports ? (
            weeklyReports.dailyReports?.map((daily, index) => {
              if (!daily) {
                return null;
              }

              const dailyDate = new Date(daily.createdAt);
              const displayDate = `${
                dailyDate.getMonth() + 1
              }/${dailyDate.getDate()} ${WEEKDAYS[dailyDate.getDay()]}`;

              return (
                <Fragment key={daily.id}>
                  <TableDate>{displayDate}</TableDate>
                  <DailyReportsWrapper>
                    <DailyReportButton
                      href={`/detail/record/${daily.id}`}
                      target="_blank"
                    >
                      돌봄기록 {index + 1}
                    </DailyReportButton>
                  </DailyReportsWrapper>
                </Fragment>
              );
            })
          ) : (
            <NoDailyReportsMessage>일일 돌봄기록 없음</NoDailyReportsMessage>
          )}
        </TableRow>
      </TablesRowsWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div(() => ({
  display: "flex",
  width: "100%",
  borderBottom: "2px solid black",

  "&:last-of-type": {
    border: "none",
  },
}));

const WeekLabel = styled.div(({ theme }) => ({
  width: 150,
  backgroundColor: theme.color.gray,
  fontWeight: "bold",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  padding: "0px 10px",
}));

const WeeklyReportButton = styled.span<{ $hasWeeklyReport: boolean }>(
  ({ theme, $hasWeeklyReport }) => ({
    cursor: $hasWeeklyReport ? "pointer" : "default",
    transition: "all 0.1s ease-in",
    textAlign: "center",
    fontSize: 14,
    fontWeight: "normal",
    width: "100%",
    borderRadius: 5,

    ...($hasWeeklyReport && {
      "&:hover": {
        color: theme.color.navy,
      },
    }),
  })
);

const TablesRowsWrapper = styled.div(() => ({
  dispaly: "flex",
  flexDirection: "column",
  flexGrow: 1,
}));

const TableRow = styled.div<{ $isEmpty: boolean }>(({ theme, $isEmpty }) => ({
  display: $isEmpty ? "flex" : "grid",
  gridTemplateColumns: "1fr 8fr",
  width: "100%",
  borderBottom: `1px solid ${theme.color.lightGray}`,
}));

const TableDate = styled.p(({ theme }) => ({
  textAlign: "center",
  padding: "10px 5px",
  backgroundColor: theme.color.lightGray,
  color: theme.color.darkGray,
}));

const DailyReportsWrapper = styled.div(({ theme }) => ({
  display: "flex",
  flexGrow: 1,
  overflowY: "auto",

  "&>p": {},
}));

const NoDailyReportsMessage = styled.p(({ theme }) => ({
  color: theme.color.darkGray,
  height: 100,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
}));

const DailyReportButton = styled.a(({ theme }) => ({
  width: 200,
  padding: "5px 20px",
  borderRight: `1px solid ${theme.color.gray}`,
  borderLeft: `1px solid ${theme.color.gray}`,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  transition: "all 0.1s ease-in",
  cursor: "pointer",
  textDecoration: "none",

  "&:hover": {
    backgroundColor: theme.color.lightNavy,
  },
}));
