import styled from "styled-components";
import WeeklyRecordsRow from "./WeeklyRecordsRow";
import { DailyReportGroupByWeek } from "../../../lib/apollo/graphql/generated";
import Loader from "../../common/components/Loader";

interface MonthlyRecordTableContentsProps {
  period: {
    year: number;
    month: number;
  };
  data: DailyReportGroupByWeek[];
  isLoading: boolean;
  receiverId: string;
}

export default function MonthlyRecordTableContents({
  period,
  data,
  isLoading,
  receiverId,
}: MonthlyRecordTableContentsProps) {
  return isLoading ? (
    <div style={{ height: "100%", justifyContent: "center", padding: 100 }}>
      <Loader />
    </div>
  ) : (
    <Table>
      {data?.map((weekly, index) => {
        return (
          <WeeklyRecordsRow
            receiverId={receiverId}
            key={`__weekly_row_${index}`}
            weekIndex={index + 1}
            period={period}
            weeklyReports={weekly}
          />
        );
      })}
    </Table>
  );
}

const Table = styled.div(() => ({
  display: "flex",
  maxHeight: "calc(100% - 80px)",
  flexDirection: "column",
  overflowY: "auto",
}));
