import { useState } from "react";
import {
  DailyReportGroupByWeek,
  useGetDailyReportsByMonthWeekAdminQuery,
  useGetReceiverNameQuery,
} from "../../../lib/apollo/graphql/generated";

const today = new Date();

export const useMonthlyReceord = (receiverId: string) => {
  const [period, setPeriod] = useState({
    year: today.getFullYear(),
    month: today.getMonth() + 1,
  });

  const { data: receiverData, loading: receiverLoading } =
    useGetReceiverNameQuery({
      variables: {
        receiverId,
      },
    });

  const { data, loading } = useGetDailyReportsByMonthWeekAdminQuery({
    variables: {
      receiverId,
      year: period.year.toString(),
      month: period.month.toString(),
    },
  });

  const onNextMonth = () => {
    if (isNextDisabled) {
      return;
    }

    setPeriod((previous) => {
      const isNextYear = previous.month + 1 > 12;

      return {
        year: isNextYear ? previous.year + 1 : previous.year,
        month: isNextYear ? 1 : previous.month + 1,
      };
    });
  };

  const onPreviousMonth = () => {
    setPeriod((previous) => {
      const isPrevYear = previous.month - 1 <= 0;

      return {
        year: isPrevYear ? previous.year - 1 : previous.year,
        month: isPrevYear ? 12 : previous.month - 1,
      };
    });
  };

  const isNextDisabled =
    period.year === today.getFullYear() &&
    period.month - 1 === today.getMonth();

  return {
    models: {
      period,
      receiver: receiverData?.getReceiver.name,
      isLoading: loading || receiverLoading,
      isNextDisabled,
      data: data?.getDailyReportsByMonthWeekAdmin as DailyReportGroupByWeek[],
    },
    operations: {
      onNextMonth,
      onPreviousMonth,
    },
  };
};
