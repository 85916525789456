import styled from "styled-components";

interface MeasureStatusIconProps {
  measure: number;
}

export default function MeasureStatusIcon({ measure }: MeasureStatusIconProps) {
  const { text, styles } = MEASURE_TEXTS[measure];

  return <MeasureIcon style={styles}>{text}</MeasureIcon>;
}

const MeasureIcon = styled.div(() => ({
  borderRadius: 20,
  padding: "5px 20px",
  fontSize: 14,
}));

const MEASURE_TEXTS: {
  text: string;
  styles: React.CSSProperties;
}[] = [
  {
    text: "해당없음",
    styles: {
      border: "1px solid gray",
      backgroundColor: "lightgray",
      color: "black",
    },
  },
  {
    text: "관리필요",
    styles: {
      border: "1px solid black",
      backgroundColor: "crimson",
      color: "white",
    },
  },
  {
    text: "주의요망",
    styles: {
      border: "1px solid black",
      backgroundColor: "darkorange",
      color: "black",
    },
  },
  {
    text: "약간불편",
    styles: {
      border: "1px solid black",
      backgroundColor: "gold",
      color: "black",
    },
  },
  {
    text: "보통",
    styles: {
      border: "1px solid black",
      backgroundColor: "skyblue",
      color: "black",
    },
  },
  {
    text: "양호",
    styles: {
      border: "1px solid green",
      backgroundColor: "lightgreen",
      color: "black",
    },
  },
];
