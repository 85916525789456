import styled from "styled-components";
import { CategoryTree } from "../containers/WeeklyRecordDetailContainer.hook";
import { useMediumCategoryViewer } from "./MediumCategoryViewer.hook";
import MeasureStatusIcon from "./MeasureStatusIcon";
import Loader from "../../common/components/Loader";

export interface MediumCategoryViewerProps {
  category: string | null;
  categoryTree: CategoryTree;
  receiverId: string;
  year: string;
  month: string;
  week: string;
}

export default function MediumCategoryViewer(props: MediumCategoryViewerProps) {
  const {
    models: {
      mediums,
      selectedMedium,
      isFullReportLoading,
      totalComments,
      categoryComments,
    },
    operations: { onMediumCategoryClick },
  } = useMediumCategoryViewer(props);

  if (!props.category || !mediums) {
    return (
      <Wrapper>
        <UnselectedMessage>대분류를 선택해 주세요</UnselectedMessage>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Title>
        주간 기록 내용{" "}
        <span>(각 항목을 클릭해 코멘트를 확인할 수 있습니다)</span>
      </Title>
      {Object.keys(mediums).map((mediumCategory) => {
        const smalls = mediums[mediumCategory];
        const isSelected = mediumCategory === selectedMedium;

        return (
          <MediumCategoryItem key={mediumCategory}>
            <MediumCategoryTitle
              onClick={() => onMediumCategoryClick(mediumCategory)}
            >
              <p>{mediumCategory}</p>
              <i className="bi bi-chevron-down" />
            </MediumCategoryTitle>
            {isSelected ? (
              isFullReportLoading ? (
                <CommentLoader>
                  <Loader />
                </CommentLoader>
              ) : (
                <p>{totalComments}</p>
              )
            ) : (
              <></>
            )}
            {smalls.map((smallCategory, index) => (
              <SmallCategoryItem key={smallCategory.small}>
                <SmallCategoryTitle>
                  <p>
                    {index + 1}. {smallCategory.small}
                  </p>
                  <MeasureStatusIcon measure={smallCategory.measure} />
                </SmallCategoryTitle>
                {isSelected ? (
                  isFullReportLoading ? (
                    <CommentLoader>
                      <Loader />
                    </CommentLoader>
                  ) : (
                    <p>{categoryComments?.[index]?.comment}</p>
                  )
                ) : (
                  <></>
                )}
              </SmallCategoryItem>
            ))}
          </MediumCategoryItem>
        );
      })}
    </Wrapper>
  );
}

const Wrapper = styled.div(({ theme }) => ({
  border: `1px solid ${theme.color.gray}`,
  borderRadius: 10,
  display: "flex",
  flexDirection: "column",
  gap: 10,
  padding: 20,
  width: "70%",
  maxWidth: "70%",
}));

const Title = styled.p(({ theme }) => ({
  margin: 0,
  fontWeight: "bold",

  "&>span": {
    fontWeight: "normal",
    color: theme.color.darkGray,
  },
}));

const UnselectedMessage = styled.p(() => ({
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: 18,
}));

const MediumCategoryItem = styled.div(({ theme }) => ({
  borderRadius: 5,
  border: `1px solid ${theme.color.darkGray}`,
  padding: 20,
  display: "flex",
  flexDirection: "column",
  gap: 10,
}));

const MediumCategoryTitle = styled.div(() => ({
  display: "flex",
  justifyContent: "space-between",
  cursor: "pointer",

  "&>p": {
    margin: 0,
    fontWeight: "bold",
    fontSize: 20,
  },
}));

const SmallCategoryItem = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 10,
}));

const SmallCategoryTitle = styled.div(() => ({
  display: "flex",
  justifyContent: "space-between",

  "&>p": {
    margin: 0,
  },
}));

const CommentLoader = styled.div(() => ({
  backgroundColor: "ghostwhite",
  height: 100,
}));
