import { useParams } from "react-router-dom";
import PageWrapper from "../../common/components/PageWrapper";
import MonthlyRecord from "../components/MonthlyRecord";

export default function RecordsByReceiverContainer() {
  const { receiverId } = useParams();

  if (!receiverId || receiverId === ":receiverId") {
    return (
      <PageWrapper>
        <p>올바른 수급자를 선택해 주세요.</p>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <MonthlyRecord receiverId={receiverId} />
    </PageWrapper>
  );
}
