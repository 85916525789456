import styled from "styled-components";
import Loader from "../../common/components/Loader";
import PageWrapper from "../../common/components/PageWrapper";
import { useWeeklyRecordDetailConatiner } from "./WeeklyRecordDetailContainer.hook";
import LargeCategoryList from "../components/LargeCategoryList";
import MediumCategoryViewer from "../components/MediumCategoryViewer";

export default function WeeklyRecordDetailContainer() {
  const {
    models: {
      selectedCategory,
      categoryTree,
      loading,
      year,
      month,
      week,
      receiverId,
      receiver,
    },
    operations: { onLargeCategorySelect },
  } = useWeeklyRecordDetailConatiner();

  if (loading) {
    return <Loader />;
  }

  return (
    <PageWrapper>
      <TitleRow>
        <p>
          {year}년 {month}월 {week}주차 기록
        </p>
        <p>{receiver} 수급자님</p>
      </TitleRow>
      <ContentsWrapper>
        <LargeCategoryList
          categoryTree={categoryTree}
          category={selectedCategory}
          onSelect={onLargeCategorySelect}
        />
        <MediumCategoryViewer
          category={selectedCategory}
          categoryTree={categoryTree}
          receiverId={receiverId}
          year={year}
          month={month}
          week={week}
        />
      </ContentsWrapper>
    </PageWrapper>
  );
}

const TitleRow = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 1,
  marginBottom: 10,

  "&>p": {
    margin: 0,

    "&:first-of-type": {
      fontWeight: "bold",
      fontSize: 20,
    },
  },
}));

const ContentsWrapper = styled.div(() => ({
  display: "flex",
  gap: 10,
  flexGrow: 1,
}));
