import { useState } from "react";
import { MediumCategoryViewerProps } from "./MediumCategoryViewer";
import { useGetReceiverWeeklyReportWithTotalCommentLazyQuery } from "../../../lib/apollo/graphql/generated";

export const useMediumCategoryViewer = ({
  category: selectedCategory,
  categoryTree,
  receiverId,
  year,
  month,
  week,
}: MediumCategoryViewerProps) => {
  const [selectedMedium, setSelectedMedium] = useState<null | string>(null);
  const mediums = selectedCategory
    ? categoryTree[selectedCategory].mediums
    : undefined;

  const [getFullReport, { data, loading }] =
    useGetReceiverWeeklyReportWithTotalCommentLazyQuery();

  const onMediumCategoryClick = (category: string | null) => {
    if (category === selectedMedium) {
      setSelectedMedium(null);
      return;
    }

    setSelectedMedium(category);

    const smalls = mediums?.[category!] || [];
    const categoryId = categoryTree[selectedCategory!]?.id || "";

    if (!categoryId || !smalls.length) {
      console.warn("CategoryId or smalls are invalid. Aboriting lazy query.");
      return;
    }

    const categoryMeasures = smalls.map((small) => ({
      categoryId,
      measure: small.measure,
    }));

    getFullReport({
      variables: {
        receiverId,
        categoryMeasures,
        year: +year,
        month: +month,
        week: +week,
      },
    });
  };

  return {
    models: {
      selectedMedium,
      mediums,
      isFullReportLoading: loading,
      totalComments: data?.getReceiverWeeklyReportWithTotalComment.totalComment,
      categoryComments:
        data?.getReceiverWeeklyReportWithTotalComment.categoryComments,
    },
    operations: {
      onMediumCategoryClick,
    },
  };
};
