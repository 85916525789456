import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { PRIVATE_ROUTES } from "../utils/router";
import { PrivateRoute } from "routes";

export const useTopbar = () => {
  const location = useLocation();
  const fullPath = location.pathname;
  const key = (fullPath.split("/")[1].toUpperCase() ||
    "MAIN") as keyof PrivateRoute;

  const [title, setTitle] = useState("");

  const isSameAsFullPath = useCallback(
    (path?: string) => {
      if (!path) {
        return false;
      }

      const staticPath = path.split("/:")[0];
      return fullPath.startsWith(staticPath);
    },
    [fullPath]
  );

  const getPathName = useCallback(() => {
    const route = PRIVATE_ROUTES[key];

    if (route.children?.length) {
      const selected = route.children.find(({ path }) =>
        isSameAsFullPath(path)
      );

      setTitle(selected?.name || route.name!);
    } else {
      setTitle(route.name!);
    }
  }, [key, isSameAsFullPath]);

  useEffect(() => {
    getPathName();
  }, [location?.pathname, getPathName]);

  return {
    models: {
      title,
    },
  };
};
