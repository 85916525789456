import { useGetReportReasonsQuery } from "../../../lib/apollo/graphql/generated";
import { useModal } from "../../../hooks/useModal";

function useReportContainer() {
  const [isOpen, { open, close }] = useModal();

  const { data, loading } = useGetReportReasonsQuery({
    variables: {
      active: true,
    },
  });

  if (!loading) console.log(data?.getReportReasons);

  return {
    models: {
      isAdding: isOpen,
      loading,
      data: data?.getReportReasons,
    },
    operations: {
      openAddModal: open,
      closeAddModal: close,
    },
  };
}

export default useReportContainer;
