import { CategoryTree } from "../containers/WeeklyRecordDetailContainer.hook";
import styled from "styled-components";

interface LargeCategoryListProps {
  categoryTree: CategoryTree;
  category: string | null;
  onSelect: (categoryName: string | null) => void;
}

export default function LargeCategoryList({
  categoryTree,
  category: selectedCategory,
  onSelect,
}: LargeCategoryListProps) {
  const onLargeCategoryClick = (categoryName: string) => {
    const isSelected = categoryName === selectedCategory;

    if (isSelected) {
      onSelect(null);
    } else {
      onSelect(categoryName);
    }
  };

  return (
    <LargeCateogryList>
      <p>대분류 목록</p>
      {Object.keys(categoryTree).map((categoryName) => {
        const category = categoryTree[categoryName];
        const mediumCount = Object.keys(category.mediums).length;
        const isSelected = categoryName === selectedCategory;

        return (
          <LargeCategoryItem
            key={category.id}
            $isSelected={isSelected}
            onClick={() => onLargeCategoryClick(categoryName)}
          >
            <p>
              {categoryName} <span>{mediumCount}</span>
            </p>
            <i className="bi bi-arrow-right"></i>
          </LargeCategoryItem>
        );
      })}
    </LargeCateogryList>
  );
}

const LargeCateogryList = styled.div(({ theme }) => ({
  border: `1px solid ${theme.color.gray}`,
  borderRadius: 10,
  padding: 20,
  display: "flex",
  flexDirection: "column",
  gap: 10,
  overflowY: "auto",
  width: "30%",

  "&>p": {
    fontWeight: "bold",
    margin: 0,
  },
}));

const LargeCategoryItem = styled.div<{ $isSelected: boolean }>(
  ({ theme, $isSelected }) => ({
    border: `1px solid ${
      $isSelected ? theme.color.lightNavy : theme.color.darkGray
    }`,
    borderRadius: 5,
    height: 80,
    display: "flex",
    alignItems: "center",
    padding: 10,
    fontSize: 18,
    gap: 3,
    color: $isSelected ? theme.color.white : theme.color.darkGray,
    cursor: "pointer",
    transition: "0.1s ease-in",
    backgroundColor: $isSelected ? "steelblue" : "white",

    ...(!$isSelected && {
      "&:hover": {
        color: theme.color.lightNavy,
      },
    }),

    "&>p": {
      fontWeight: "bold",
      margin: 0,
      display: "flex",
      gap: 10,

      "&>span": {
        fontWeight: "normal",
        opacity: "70%",
      },
    },

    "&>i": {
      marginLeft: "auto",
    },
  })
);
