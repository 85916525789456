import { createBrowserRouter, Outlet } from "react-router-dom";
import { PrivateRoute, PublicRoute } from "routes";
import AnnouncementContainer from "../../../modules/announcement/container/AnnouncementContainer";
import AuthContainer from "../../../modules/auth/container/AuthContainer";
import CommunityDetailContainer from "../../../modules/communityDetail/container/CommunityDetailContainer";
import MainContainer from "../../../modules/main/container/MainContainer";
import ManagementContainer from "../../../modules/management/container/ManagementContainer";
import PolicyContainer from "../../../modules/policy/container/PolicyContainer";
import UserDetailContainer from "../../../modules/userDetail/container/UserDetailContainer";
import ExampleQuestionsContainer from "../../../modules/exampleQuestion/container/ExampleQuestionsContainer";
import RouteLayout from "../components/RouteLayout";
import InfoContainer from "../../../modules/caregiverInfo/container/InfoContainer";
import ServiceContainer from "../../../modules/service/container/ServiceContainer";
import PackageContainer from "../../../modules/package/container/PackageContainer";
import ReportContainer from "../../../modules/report/container/ReportContainer";
import RecordContainer from "../../../modules/record/containers/RecordContainer";
import RecordDetailContainer from "../../../modules/record/containers/RecordDetailContainer";
import CaregiverList from "../../../modules/users/container/CaregiverList";
import ReceiverList from "../../../modules/users/container/ReceiverList";
import GuardianList from "../../../modules/users/container/GuardianList";
import RecordsByReceiverContainer from "../../../modules/record/containers/RecordsByReceiverContainer";
import WeeklyRecortDetailContainer from "../../../modules/record/containers/WeeklyRecordDetailContainer";

export const PUBLIC_ROUTES: PublicRoute = {
  SIGN_IN: {
    name: "로그인",
    element: <AuthContainer />,
    show: false,
    path: "/",
  },
};

export const PRIVATE_ROUTES: PrivateRoute = {
  MAIN: {
    name: "통계",
    element: <Outlet />,
    show: true,
    path: "/",
    children: [
      {
        name: "Dashboard",
        element: <MainContainer />,
        show: true,
        index: true,
      },
    ],
  },
  USERS: {
    name: "사용자 조회",
    element: <Outlet />,
    show: true,
    path: "/users",
    children: [
      {
        name: "보호사 회원 관리",
        element: <CaregiverList />,
        show: true,
        path: "/users/caregivers",
      },
      {
        name: "보호자 회원 관리",
        element: <GuardianList />,
        show: true,
        path: "/users/guardians",
      },
      {
        name: "수급자 회원 관리",
        element: <ReceiverList />,
        show: true,
        path: "/users/receivers",
      },
    ],
  },
  PRODUCT: {
    name: "결제상품",
    element: <Outlet />,
    show: true,
    path: "/product",
    children: [
      {
        name: "포인트상품 관리",
        element: <PackageContainer />,
        path: "/product/package",
        show: true,
      },
      {
        name: "부가서비스 관리",
        element: <ServiceContainer />,
        path: "/product/service",
        show: true,
      },
    ],
  },
  DETAIL: {
    name: "상세조회",
    element: <Outlet />,
    show: false,
    path: "/detail",
    children: [
      {
        name: "보호사 프로필",
        element: <UserDetailContainer type="caregivers" />,
        show: false,
        path: "/detail/caregivers/:userId",
      },
      {
        name: "보호자 프로필",
        element: <UserDetailContainer type="guardians" />,
        show: false,
        path: "/detail/guardians/:userId",
      },
      {
        name: "수급자 프로필",
        element: <UserDetailContainer type="receivers" />,
        show: false,
        path: "/detail/receivers/:userId",
      },
      {
        name: "게시물 상세조회",
        element: <CommunityDetailContainer />,
        show: false,
        path: "/detail/post/:postId",
      },
      {
        name: "일간기록 상세조회",
        element: <RecordDetailContainer />,
        show: false,
        path: "/detail/record/:recordId",
      },
      {
        name: "주간기록 상세조회",
        element: <WeeklyRecortDetailContainer />,
        show: false,
        path: "/detail/weekly/:receiverId/:period",
      },
    ],
  },
  ANNOUNCEMENT: {
    name: "공지사항",
    element: <Outlet />,
    show: true,
    path: "/announcement",
    children: [
      {
        name: "공지사항 관리",
        element: <AnnouncementContainer />,
        show: true,
        path: "/announcement/list",
      },
      {
        name: "정보글 관리",
        element: <InfoContainer />,
        show: true,
        path: "/announcement/info",
      },
    ],
  },
  RECORD: {
    name: "돌봄기록",
    element: <Outlet />,
    show: true,
    path: "/record",
    children: [
      {
        name: "돌봄기록 관리",
        element: <RecordContainer />,
        path: "/record/all",
        show: true,
      },
      {
        name: "월별 기록현황",
        element: <RecordsByReceiverContainer />,
        path: "/record/receiver/:receiverId",
        show: false,
      },
    ],
  },
  INFORMATION: {
    name: "정보",
    element: <Outlet />,
    show: true,
    path: "/information",
    children: [
      {
        name: "예시질문 관리",
        element: <ExampleQuestionsContainer />,
        path: "/information/questions",
        show: true,
      },
      {
        name: "기업정보 관리",
        element: <ManagementContainer />,
        path: "/information/manage",
        show: true,
      },
      {
        name: "이용약관 관리",
        element: <PolicyContainer />,
        path: "/information/terms",
        show: true,
      },
      {
        name: "개인정보처리방침 관리",
        element: <PolicyContainer />,
        path: "/information/privacy",
        show: true,
      },
      {
        name: "신고사유 관리",
        element: <ReportContainer />,
        path: "/information/report",
        show: true,
      },
    ],
  },
};

export const publicRouter = createBrowserRouter(Object.values(PUBLIC_ROUTES));
export const privateRouter = createBrowserRouter([
  {
    element: <RouteLayout />,
    path: "/",
    children: Object.values(PRIVATE_ROUTES),
  },
]);
